.contact {
    text-align: center;
    .container {
        max-width: 1200px;
        margin: auto;

        .flex_wrapper {
            display: flex;
            gap: 2rem;
            justify-content: center;
            align-items: center;
            .content {
                flex: 1;
                padding: 1rem;
                .title {
                    font-size: 3rem;
                }

                .subtitle {
                    margin-top: 1rem;
                }

                .details {

                }

                .contact_details {
                    
                    .loc {
                        font-size: 5rem;
                        margin-top: 2rem;
                        
                    }

                    .address {
                        margin-bottom: 1rem;
                    }

                    .flex {
                        display: flex;
                        justify-content: center;

                        .icon {
                            padding: 0.2rem 0.2rem 0 0;
                        }
                    }

                    .flex2 {
                        display: flex;
                        justify-content: center;

                        .icon {
                            padding: 0.2rem 0.2rem 0 0;
                        }
                    }
                }
            }

            .img {
                flex: 1.5;
            }
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }

//Medium devices (tablets, less than 992px)
@media (max-width: 991.98px ) {
    .hidden {
        height: 71px;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    
}

// Small Mobile Phones
@media (max-width: 575.98px) {
    .contact {
        .container {
            .flex_wrapper {
                flex-direction: column-reverse;

                .img {
                    width: 90vw;
                }
            }
        }
    }
}
