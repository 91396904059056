.footer {
    background-color: #333;
    padding: 2rem 1rem 2rem 1rem;
    color: white;
    text-align: center;
    .container {
        max-width: 1200px;
        margin: auto;

        .flex_wrapper {
            display: flex;
            .about {
                flex: 1;
                img {
                    max-width: 200px;
                }
                p {
                    text-align: justify;
                    margin-top: 1rem;
                }
            }

            .links {
                flex: 1;
                
                .list {
                    list-style: none;
                }

                li {
                    a {
                        text-decoration: none;
                        color: white;
                    }

                    a:hover {
                        color: orange;
                    }
                }
                
                .title {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

.copy {
    background-color: #000000;
    height: 40px;
    color: white;
    text-align: center;
    position: relative;

    p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        a{
            text-decoration: none;
            color: white;
        }

        a:hover{
            color: orange;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }

//Medium devices (tablets, less than 992px)
@media (max-width: 991.98px ) {
    
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    
}

// Small Mobile Phones
@media (max-width: 575.98px) {
    .footer {
        .container {
            .flex_wrapper {
                flex-direction: column;
                gap: 2rem;
            }
        }
    }
    .copy {
        height: 100px;
    }
}
