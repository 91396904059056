.backdrop {
    display: none;
}

@media (max-width: 575.98px) {
    .backdrop {
        display: block;
        height: 100vh;
        width: 100vw;
        background: rgba(121, 121, 121, 0.212);
        z-index: 200;
    }
}