.login {
    .container {
        max-width: 1200px;
        margin: auto;
        padding: 1rem;

        .flex_wrapper {
            background: orange;
            max-width: 500px;
            display: flex;
            justify-content: center;
            margin: auto;
            .form {
                .title {
                    text-align: center;

                    h2 {
                        margin: 1rem 0;
                    }

                    .subtitle {
                        margin-bottom: 1rem;
                    }
                }

                .input_wrapper {
                    .input_group {
                        display: flex;
                        justify-content: space-between;
                        margin: 0.5rem 0;

                        input {
                            padding: 0.2rem 0.5rem;
                            font-size: 1.1rem;
                            outline: none;
                            margin-left: 1rem;
                            border: none;
                            border-radius: 5px;
                        }

                        input:focus{
                            border: 1px dashed green;
                        }

                    }

                    .error {
                        color: white;
                        background: rgb(173, 0, 0);
                        text-align: center;
                    }
                    .submit_group {
                        text-align: center;
                        margin: 1rem;
                        
                        .submit {
                            text-decoration: none;
                            color: white;
                            padding: 15px 40px;
                            background-color: green;
                            display: inline-block;
                            border-radius: 100px;
                            text-transform: uppercase;
                            transition: all .2s ease;
                            position: relative;
                            cursor: pointer;
                            border: none;
                        }

                        .submit:hover {
                            transform: translateY(-3px);
                            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.212);
                        }
                        .submit:active {
                            outline: none;
                            transform: translateY(-1px);
                            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.212);
                        }
                    }
                }
            }
        }
    }
}