.bars {
    display: none;
}

@media (max-width: 575.98px) {
    .bars {
        display: inline-block;
        font-size: 1.5rem;
        position: absolute;
        top: 10px;
        left: 97%;
        transform: translateX(-97%);
        text-decoration: none;
        color: black;
    }
}