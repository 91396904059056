.toc {
    .container {
        max-width: 1200px;
        margin: auto;
        text-align: justify;
        padding: 1rem;

        h3 {
            margin-top: 2rem;
        }

        p {
            margin: 1rem 0;
        }

        ul {
            li {
                margin-left: 5rem;
            }
        }
    }
}