.service_card {
    margin: 1rem 0;
    .container {
        max-width: 1200px;
        margin: auto;
        padding: 1rem;

        .flex_wrapper {
            display: flex;
            gap: 2rem;
            align-items: center;
            .content {
                flex: 1;
                .title {
                    font-size: 3rem;
                }

                .details {
                    text-align: justify;
                    margin-bottom: 1rem;
                }


                
            }

            .image {
                flex: 1;
            }
        }

        .flex_wrapper2 {
            display: flex;
            flex-direction: row-reverse;
            gap: 2rem;
            align-items: center;
            .content {
                flex: 1;

                .title {
                    font-size: 3rem;
                    margin-bottom: 1rem;
                }

                .details {
                    text-align: justify;
                }

                
            }

            .image {
                flex: 1;
            }
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

//Medium devices (tablets, less than 992px)
@media (max-width: 991.98px ) {
    
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Small Mobile Phones
@media (max-width: 575.98px) {
    .service_card {
        .container {
            .flex_wrapper{
                flex-direction: column-reverse;

                .content {
                    .title {
                        font-size: 2rem;
                    }
                }

                .image {
                    min-width: 90vw;
                }
            }

            .flex_wrapper2{
                flex-direction: column-reverse;

                .content {
                    .title {
                        font-size: 2rem;
                    }
                }

                .image {
                    min-width: 90vw;
                }
            }
        }
    }
}