.contact {
    flex: 1;
    .icon {
        font-size: 4rem;
    }

    .address {

    }

    .phone_wrapper {
        display: flex;
        justify-content: center;
        margin-top: 0.7rem;

        .phone {
            padding-top: 0.2rem;
            margin-right: 0.2rem;
        }
    }

    .mail_wrapper {
        display: flex;
        justify-content: center;

        .email {
            padding-top: 0.2rem;
            margin-right: 0.2rem;
        }
    }
}