.title {
    background-image: linear-gradient(to right,rgba(255, 255, 255, 0.925), rgba(255, 255, 255, 0.945)), url('./pagetitlebg.png');
    height: 150px;

    .container {
        max-width: 1200px;
        margin: auto;
 

        h1 {
            text-align: center;
            padding-top: 2.5rem;
            font-size: 3rem;
        }
    }
}