.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(238, 238, 238);
    position: relative;
    overflow: hidden;
    transform: scale(1);
    transition: transform 0.5s ease-out;

    .icon {
        font-size: 2.5rem;
        text-align: center;
        color: orange;
        padding: 1rem 1rem 0 1rem;
    }

    .title {
        text-align: center;
        padding: 0 1rem;
    }

    .subtitle {
        text-align: justify;
        padding: 0 1rem;
    }

    .line {
        height: 10px;
        width: 100%;
        background-image: linear-gradient(to right, rgb(255, 217, 0) , orange);
        margin: 1rem 0 0 0;
        transform: translateX(-100%);
        transition: transform 0.5s ease-out;
    }

    .image {
        img {
            max-width: 100%;
        }
    }

    .name {
        padding: 1rem;
        margin: 0;
    }

    .designation {
        padding: 0 1rem;
    }
}

.card:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease-out;
    .line {
        transform: translateX(0);
    }
}

.link {
    text-decoration: none;
    color: black;
}