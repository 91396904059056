.career {
    margin: 2rem 0;
    padding: 1rem;
    .container {
        max-width: 1200px;
        margin: auto;

        .position {
            font-size: 2rem;
            margin-bottom: 2rem;
        }

        .description {
            margin-bottom: 2rem;
            text-align: justify;
        }

        .resp {
            p {
                font-weight: bold;
                text-decoration: underline;
                margin-bottom: 0.5rem;
            }

            .resp_list {
                margin-left: 3rem;
                margin-bottom: 2rem;
            }
        }

        .salary {
            margin-bottom: 2rem;
        }

        .apply {
            .mail {
                text-decoration: underline;
                color: orange;
                cursor: pointer;
                font-weight: bold;
            }
        }
    }
}