.peoples {
    .container {
        max-width: 1200px;
        margin: auto;

        .grid_wrapper {
            display: grid;
            grid-template-columns: repeat(5, auto);
            gap: 1rem;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

//Medium devices (tablets, less than 992px)
@media (max-width: 991.98px ) {
    .peoples {
        .container {
            .grid_wrapper {
                grid-template-columns: repeat(4, auto);
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .peoples {
        .container {
            .grid_wrapper {
                grid-template-columns: repeat(3, auto);
            }
        }
    }
}

// Small Mobile Phones
@media (max-width: 575.98px) {
    .peoples {
        .container {
            .grid_wrapper {
                grid-template-columns: repeat(2, auto);
            }
        }
    }
}