.tray {
    display: none;
}

@media (max-width: 575.98px) {
    .tray {
        display: block;
        background: orange;
        text-align: center;
        position: absolute;
        box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        width: 55vw;
        height: 100vh;
        z-index: 400;
        transform: translateX(-110%);
        transition: transform 0.3s ease-out;

        .tray_logo {
            display: block;
            padding: 10px;
        }
    
        .tray_list {
            margin-top: 4rem;
            list-style: none;
    
            .tray_item {
                display: block;
                padding: 0.5rem;
                a {
                    text-decoration: none;
                    color: white;
                    margin: 10px;
                    font-weight: bold;
                }
            }
        }
    }

    .open{
        display: block;
        background: orange;
        text-align: center;
        position: absolute;
        box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        width: 55vw;
        height: 100vh;
        z-index: 400;
        transform: translateX(0);
        transition: transform 0.3s ease-out;
        .tray_logo {
            display: block;
            padding: 10px;
        }
    
        .tray_list {
            margin-top: 4rem;
            list-style: none;
    
            .tray_item {
                display: block;
                padding: 0.5rem;
                a {
                    text-decoration: none;
                    color: white;
                    margin: 10px;
                    font-weight: bold;
                }
            }
        }
    }
}
