.error {
    .container {
        max-width: 1200px;
        margin: auto;

        .btn {
            text-align: center;
            margin-bottom: 3rem;
        }
    }
}
