.feature {
    display: inline-block;
    font-size: 1.2rem;
    background-color: #E5E5E5;
    border-radius: 100px;
    padding: 0.4rem 1rem;
    margin-bottom: 0.3rem;

    .flex_wrapper {
        display: flex;
    }
        .icon {
            font-size: 1.5rem;
            padding-top: 0.2rem;
            padding-right: 0.5rem;
            color: green;
            min-width: 30px;
        }
}

.feature:hover {
    background: rgb(255, 241, 215);
    cursor: pointer;
}