.homepage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
}

.hidden {
    width: 100%;
    height: 76px;
    background: orange;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }

//Medium devices (tablets, less than 992px)
@media (max-width: 991.98px ) {
    .hidden {
        height: 71px;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    
}

// Small Mobile Phones
@media (max-width: 575.98px) {
    .hidden {
        height: 50px;
    }
}

