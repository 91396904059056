.btn:link,
.btn:visited {
    text-decoration: none;
    color: white;
    padding: 15px 40px;
    background-color: green;
    display: inline-block;
    border-radius: 100px;
    text-transform: uppercase;
    transition: all .2s ease;
    position: relative;
}

.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.212);
}

.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.212);
}

.btn::after {
    content: "";
    height: 100%;
    width: 100%;
    display: inline-block;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: green;
    z-index: -1;
    transition: all .4s ease;
}

.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}
