.header {
    background-color: orange;
    .container {
        max-width: 1200px;
        margin: auto;
        .flex_wrapper {
            display: flex;
            justify-content: space-between;
            position: relative;
            .logo {
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);
            }
    
            .menu_wrapper {
                flex-grow: 1;
                .menu {
                    display: flex;
                    justify-content: flex-end;
                    list-style: none;
                    padding-right: 1rem;
                    .menu_item{
                        a {
                            display: inline-block;
                            text-decoration: none;
                            color: black;
                            width: 90px;
                            text-align: center;
                            padding: 1rem 0.2rem;
                        }

                        a:last-child{
                            padding: 1rem 0 1rem 0.2rem;
                        }
    
                        a:hover {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

.active {
    color: white !important;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

//Medium devices (tablets, less than 992px)
@media (max-width: 991.98px ) {
    
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Small Mobile Phones
@media (max-width: 575.98px) {
    .header {
        height: 50px;
        .container {
            .flex_wrapper {
                .logo {
                    top: 25px;
                }
                .menu_wrapper {
                    position: relative;
                    .menu {
                        display: none;
                    }
                }
            }
        }
    }
}