
.counter {
    margin-bottom: 3rem;
    padding: 1rem;
    .out_circle {
        width: 200px;
        height: 200px;
        background: green;
        border-radius: 100px;
        position: relative;
        .circle {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 180px;
            height: 180px;
            background: white;
            border-radius: 100px;
    
            .countup {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 3rem;
                font-weight: bold;
                color: orange;
            }

            .countup::after{
                content: '+';
            }
        }
    }

    .title {
        h2 {
            text-align: center;
            margin-top: 0.5rem;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }

//Medium devices (tablets, less than 992px)
@media (max-width: 991.98px ) {
    
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .counter {
        .out_circle {
            height: 150px;
            width: 150px;

            .circle {
                height: 130px;
                width: 130px;
            }
        }
    }
}

// Small Mobile Phones
@media (max-width: 575.98px) {
    .counter {
        margin: auto;
        margin-bottom: 1rem;
        .out_circle {
            height: 200px;
            width: 200px;

            .circle {
                height: 180px;
                width: 180px;
            }
        }
    }
}
