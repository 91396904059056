.home {
    .container {
        max-width: 1200px;
        margin: auto;

        .flex_wrapper {
            display: flex;
            margin: 2rem 0;
            padding: 1rem;

            .content {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .typist {
                    height: 100px;
                    font-size: 1.7rem;
                }
            }

            .img {
                flex: 1;
            }
        }

        .services_grid {
            display: grid;
            grid-template-columns: repeat(3, auto);
            gap: 1rem;
            margin-bottom: 3rem;
        }

        .counter {
            display: flex;
            justify-content: space-around;
        }

    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }

//Medium devices (tablets, less than 992px)
@media (max-width: 991.98px ) {
    .home {
        .container {
            .flex_wrapper {
                .content {
                    .typist {
                        font-size: 1.2rem;
                    }
                }
            }
            .services_grid {
                grid-template-columns: repeat(2, auto);
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    
}

// Small Mobile Phones
@media (max-width: 575.98px) {
    .home {
        .container {
            .flex_wrapper {
                .content {
                    .typist {
                        font-size: 0.8rem;
                    }
                }
            }
            .services_grid {
                grid-template-columns: repeat(2, auto);
            }

            .counter {
                flex-direction: column;
                margin: auto;
            }
        }
    }
}

