.career {
    margin: 1rem;
    background: #e5e5e5;
    padding: 1rem;
    
    .flex_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.career:hover {
    background: rgb(255, 241, 215);
}