.header {
    background-color: green;

    .container {
        max-width: 1200px;
        margin: auto;
        padding: 0 1rem;
        .flex_wrapper {
            display: flex;
            justify-content: space-between;

            .contact {
                display: flex;
                color: white;

                .phone_icon {
                    display: inline-block;
                    margin: 0.1rem 0.2rem 0 0;
                }

                .phone {
                    margin-right: 1rem;
                }

                .email_icon {
                    display: inline-block;
                    margin: 0.1rem 0.2rem 0 0;
                }
            }

            .pages {
                display: flex;
                list-style: none;

                .page_item {
                    a {
                        text-decoration: none;
                        color: white;
                        padding: 0 1rem;
                    }

                    a:hover {
                        background-color: white;
                        color: green;
                    }
                }
            }

            .social {
                .social_item {
                    display: inline-block;
                    text-decoration: none;
                    color: white;
                    margin: 0 .2rem;
                    margin-top: 0.1rem;
                }
            }
        }
    }
}

.active {
    background-color: white !important;
    color: green !important;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }

//Medium devices (tablets, less than 992px)
@media (max-width: 991.98px ) {
    .header{
        font-size: 0.75rem;
        .container {
            .flex_wrapper {

                .pages {
                    .page_item {
                        a {
                            padding: 0 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .header{
        font-size: 1rem;
        .container {
            .flex_wrapper {
                .contact {
                    display: none;
                }

                .pages {
                    .page_item {
                        a {
                            padding: 0 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

// Small Mobile Phones
@media (max-width: 575.98px) {
    .header{
        display: none;
    }
}

